<div class="container" [ngClass]="collection.type">

	<div class="title-row">
		<span class="title">{{collection.type | translate}}</span>

		<div class="actions">
			<button [@rotateArrow]="show"
                    #downArrow class="down-arrow"
					(click)="show = !show">
			</button>

			<div class="show-all">
				<span (click)="showAll()">{{'Show all' | translate}}</span>
			</div>

			<ansyn-import-layer [attr.tooltip-value]="'Import layer' | translate"
								tooltip-class="bottom right"></ansyn-import-layer>

			<button class="add-layer" (click)="openModal(SelectedModalEnum.edit)"
					[attr.tooltip-value]="'Create new layer' | translate" tooltip-class="bottom right">
				<i class="icon-layer-add"></i>
			</button>

		</div>


	</div>

	<ansyn-layer *ngFor="let layer of collection.data; let index = index"
				 [ngClass]="'rtl' === ('direction' | translate) ? 'rtl' : ''"
				 [@layersTrigger]="show"
				 [layer]="layer"
				 [showOnly]="collection.data.length < 2">

		<ansyn-layer-menu [disabledRemove]="shouldDisableRemoveLayer(index)"
						  (openModal)="openModal($event, layer)">
		</ansyn-layer-menu>

	</ansyn-layer>

</div>
