<div class="imageries-container"
     #imageriesContainer
	 [ngClass]="{'pin-location': pinLocationMode}"
	 [class.collapsable]="collapsable"
	 [class.footer-collapsed]="footerCollapse"
>

	<ng-template [ngIf]="mapsEntities.length > 0">
		<div *ngFor="let map of mapsEntities; trackBy: trackByFun "
			 class="map-container-wrapper"
             #dragElement
			 (click)="changeActiveImagery(map.id)"
			 [id]="map.id"
			 draggable="false">
			<ansyn-imagery-container class="map"
									 [mapState]="map"
									 [active]="activeMapId === map.id"
									 [showStatus]="mapsEntities.length > 1"
									 (onMove)="dragDropMapService.onMouseDown($event, dragElement, ids, mapsEntities)"
									 [mapsAmount]="mapsEntities.length">
			</ansyn-imagery-container>

			<div class="active-border"
				 [class.active]="activeMapId === map.id">
			</div>
		</div>

	</ng-template>
</div>

<ansyn-welcome-notification *ngIf="showWelcomeNotification$ | async"></ansyn-welcome-notification>
