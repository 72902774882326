<div class="color-picker-item">
	<input
		[id]="id"
		[disabled]="!active"
		[title]="label"
		[colorPicker]="color"
		(colorPickerChange)="colorChange.emit($event)"
		[style.background]="color"/>

	<label [for]="id">
		<div class="text">{{ label }}</div>
		<div class="color" [style.background]="color"></div>
	</label>

	<button class="no-fill"
			[class.disabled]="activeDisabled || !canToggle"
			[disabled]="activeDisabled || !canToggle"
			(click)="this.activeChange.emit(!this.active)">
		<i *ngIf="active; else notactive">
			<svg fill="#fff" width="32" height="32" viewBox="0 0 1024 1024">
				<path
					d="M871.509 358.697c-12.937-22.366-41.926-30.188-64.703-17.524l-96.218 53.503c-52.963-101.159-115.177-197.29-134.934-227.105-8.757-13.259-23.765-21.286-39.912-21.286-16.109 0-31.116 8.026-39.874 21.286-36.398 55.067-217.912 336.048-217.912 457.469 0 3.371 0.228 6.592 0.38 9.889l-107.977 60.077c-22.815 12.701-30.832 41.1-17.876 63.448 8.738 15.122 24.81 23.595 41.356 23.595 7.941 0 15.976-1.937 23.385-6.034l636.389-353.833c22.872-12.738 30.852-41.138 17.895-63.485zM535.742 280.594c30.718 49.667 63.677 106.317 91.811 160.174l-244.601 136.021c25.057-76.894 93.179-199.823 152.79-296.195zM772.099 529.878c13.222 36.445 21.428 69.445 21.428 95.163 0 139.28-115.632 252.674-257.766 252.674-84.383 0-159.193-40.151-206.247-101.792l83.51-46.427c29.863 33.689 73.764 55.105 122.737 55.105 89.797 0 162.782-71.605 162.782-159.56 0-12.44-3.742-29.107-10.125-48.642l83.681-46.52z"></path>
			</svg>
		</i>

		<ng-template #notactive>
			<i>
				<svg viewBox="0 0 1024 1024">
					<path
						d="M312.32 421.125c-13.996 26.537-26.288 53.072-35.788 78.781-12.8 34.64-20.532 67.783-20.532 97.415 0 141.227 114.773 256.017 256 256.017 64.853 0 123.733-24.32 168.533-64l-60.587-60.587c-29.867 23.893-66.987 39.253-107.947 39.253-94.293 0-170.667-76.373-170.667-170.667 0-29.44 13.653-69.12 34.56-112.64l-63.573-63.573zM375.978 484.783c32.89-67.98 84.72-146.373 136.022-213.435v-134.827c0 0-174.706 198.583-235.468 363.385 9.5-25.709 21.792-52.243 35.788-78.781l63.658 63.658zM711.68 421.146c13.995 26.537 26.287 53.071 35.789 78.781 12.8 34.64 20.531 67.784 20.531 97.416 0 141.227-114.773 255.996-256 255.996-64.853 0-123.733-24.299-168.533-63.979l60.587-60.587c29.867 23.893 66.987 39.232 107.947 39.232 94.293 0 170.667-76.352 170.667-170.645 0-29.44-13.653-69.12-34.56-112.64l63.573-63.573zM648.022 484.804c-32.89-67.98-84.72-146.394-136.022-213.456v-134.827c0 0 174.707 198.604 235.469 363.406-9.502-25.709-21.794-52.243-35.789-78.781l-63.658 63.658z"></path>
				</svg>

			</i>
		</ng-template>
	</button>

</div>
