<ng-container *ngFor="let properties of metadataValues">
	<div class="enum-fields-enumerator" [hidden]="!properties.count">
		<div class="left-side">
			<ansyn-checkbox
				[disabled]="properties.disabled"
				[ngModel]="properties.value"
				(ngModelChange)='onInputClicked(properties.name, $event)'>
				<span class="field-name" [title]="true">{{properties.displayName}}</span>
			</ansyn-checkbox>

			<span class="only-filter"
				  (click)="selectOnly(properties.name)"
				  [hidden]="properties.disabled || false">only</span>

		</div>

		<ansyn-filter-counter
			[isChecked]="properties.value"
			[count]="properties.count"
			[filteredCount]="properties.filteredCount">
		</ansyn-filter-counter>

	</div>

</ng-container>
