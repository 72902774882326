<div class="image-processing-container">
	<div class="title-wrapper">
		<p class="title">{{'Manual Image Enhancement' | translate}}
			<span class="resetAll" (click)="resetParams()">{{'Reset all' | translate}}</span>
		</p>
	</div>
	<ul class="image-processing-options">
		<li *ngFor="let param of params">
			<div class="param-info">
				<label  [for]="'image-processing-' + param">{{param.name | translate}}</label><br><br><br>
			</div>
			<div class="input-wrapper">
				<input [id]="'image-processing-' + param"
					   type="range"
					   [min]="param.min"
					   [max]="param.max"
					   [ngModel]="imageManualProcessArgs[param.name]"
					   (ngModelChange)="updateParam($event, param.name)">
				<button class="button-reset" (click)="resetOne(param)">{{'reset' | translate}}</button>
			</div>
		</li>
	</ul>
</div>
