import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'ansyn-results',
	templateUrl: './results.component.html',
	styleUrls: ['./results.component.less']
})
export class ResultsComponent implements OnInit {

	constructor() {
	}

	ngOnInit() {
	}

}
