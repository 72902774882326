<div class="remove-task-modal">
	<form #f="ngForm"
		  (ngSubmit)="onSubmit(true)">
		{{message}}
		<div class="bottom-btns">
			<button type="button" (click)="onSubmit(false)">{{'Cancel' | translate}}</button>
			<button type="submit">{{'OK' | translate}}</button>
		</div>
	</form>
</div>
