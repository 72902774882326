<div class="imagery" #imageryViewContainer (mouseleave)="mouseLeave()" (mouseenter)="mouseEnter()"
	 [style.visibility]="(isHidden$ | async) ? 'hidden' : 'visible'">
	<ansyn-imagery-status
						  class="with-background"
						  [map]="mapState"
						  [isMinimalistViewMode]="isMinimalistViewMode"
						  (onMove)="onMove.emit($event)"
						  (toggleMapSynchronization)="toggleMapSynchronization()">
	</ansyn-imagery-status>

	<ansyn-imagery-tile-progress [mapId]="mapState.id" [lowered]="showStatus && active"></ansyn-imagery-tile-progress>

	<ansyn-floating-menu [isMinimalistViewMode]="isMinimalistViewMode" [mapState]="mapState"></ansyn-floating-menu>

	<ansyn-imagery-view [settings]="mapState"></ansyn-imagery-view>

	<ansyn-imagery-loader [mapId]="mapState.id"></ansyn-imagery-loader>

	<ansyn-map-search-box *ngIf="packageConfig?.mapSearch?.active && !overlay"
						  [mapId]="mapState.id"></ansyn-map-search-box>

	<ansyn-imagery-mouse-coordinates [mapId]="mapState.id" [isVisible]="isInImagery && !isMinimalistViewMode"></ansyn-imagery-mouse-coordinates>

	<ng-container
		*ngFor="let component of entryComponents.container"
		[ansynEntryComponent]="{ component: component , mapId: mapState.id }">
	</ng-container>

</div>
