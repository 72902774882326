<input
	[id]="id"
	[name]="name"
	[value]="value"
	type="radio"
	[(ngModel)]="model"
	[disabled]="disabled"
/>

<label [for]="id">
	<div class="outer-circle">
		<div class="inner-circle"></div>
	</div>
	<ng-content></ng-content>
</label>
