<div class="goto-projection-title">
	<span> {{ title }} </span>
	<span *ngIf="notification" class="ed50-notification">{{notification?.title}}
		<a [href]="notification?.href" target="_blank">
			{{ notification?.hrefText }}
		</a>
	</span>
</div>

<div class="inputs">
	<ng-template [ngIf]="coordinates">
		<div class="input-box">
			<label>X
			<input type="number"
				   [(ngModel)]="coordinates[0]"
				   name="x"
				   (ngModelChange)="onInputs(coordinates)"
				   (mousewheel)="$event.preventDefault()"
				   autocomplete="false"/>
			</label>
		</div>
		<div class="input-box">
			<label>Y
			<input type="number"
				   [(ngModel)]="coordinates[1]"
				   name="y"
				   (ngModelChange)="onInputs(coordinates)"
				   (mousewheel)="$event.preventDefault()"
				   autocomplete="false"/>
			</label>
		</div>
		<div class="input-box narrow">
			<label>zone
			<input type="number"
				   [(ngModel)]="coordinates[2]"
				   name="z"
				   (ngModelChange)="onInputs(coordinates)"
				   (mousewheel)="$event.preventDefault()"
				   autocomplete="false"/>
			</label>
		</div>
		<div class="utm-copy-to-clipboard" (click)="copyToClipBoard()">
			<span class="icon-copy-coords"></span>
		</div>
	</ng-template>
</div>
<div>
	<p *ngIf="validationErr" class="error-message">{{'Invalid. try again' | translate}}</p>
</div>
