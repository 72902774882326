<div class="edit-case">
	<button (click)="close()" class="close" type="button">
		<span>×</span>
	</button>

	<form #f="ngForm" class="edit-case-form" (ngSubmit)="onSubmitCase(selectContextElement.selectedIndex)">

		<ansyn-input
            #nameControl
			[(ngModel)]="caseModel.name"
			select
			name="name"
			[required]="true"
			[label]="'CaseName' | translate">
		</ansyn-input>

		<div class="select-context">
			<label for="context">{{'Context' | translate}}</label>
			<select id="context"
                    #selectContextElement
					name="context" required [disabled]="editMode || true">
				<option
					*ngFor="let context of contextsList"
					[selected]="context.id === caseModel.selectedContextId">
					{{context.name}}
				</option>
			</select>
		</div>

		<div class="bottom-btns">
			<button type="button" (click)="close()">{{'Cancel' | translate}}</button>
			<button [disabled]="f.invalid || !nameControl.value?.trim()">{{'OK' | translate}}</button>
		</div>
	</form>
</div>
