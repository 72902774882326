<div class="buttons" [class.hide]="isMinimalistViewMode">

	<ng-container
		*ngFor="let component of entryComponents.status">
		<ng-container
			*ngIf="component.showFirst && component.prototype.getType() === 'buttons'"
			[ansynEntryComponent]="{ component: component , mapId: mapId }">
		</ng-container>
	</ng-container>

	<button
		*ngIf="mapsAmount > 1"
		class="small-icon-button drag-me"
		[attr.tooltip-value]="'Move' | translate"
		tooltip-class="bottom right"
		(mousedown)="onMove.emit($event)">
		<i class="icon-drag-maps"></i>
	</button>

	<button *ngIf="mapsAmount > 1"
			class="small-icon-button link-maps"
			[attr.tooltip-value]="'Momentarily link screens' | translate"
			tooltip-class="bottom right"
			[disabled]="noGeoRegistration"
			(click)="toggleMapSynchronization.emit()">
		<i class="icon icon-temporary-link"></i>
	</button>

	<button class="small-icon-button hide-layers"
			[disabled]="noGeoRegistration"
			(click)="toggleMapLayers()"
			[attr.tooltip-value]="'Show/ Hide layers' | translate"
			tooltip-class="bottom right">
		<i [class.icon-layers-show]="!hideLayers"
		   [class.icon-layers-hide]="hideLayers"></i>
	</button> 
 
	<button class="small-icon-button perspective" *ngIf="mapFacadeConfig?.imageryStatusConfig?.active"
			[disabled]="noGeoRegistration"
			(click)="toggleImageryPerspective()"
			[attr.tooltip-value]="orientation | translate"
			tooltip-class="bottom right">
		<i class="icon-status-bar-orientation" [class.active]="perspective"></i>
	</button>

	<ng-container
		*ngFor="let component of entryComponents.status">
		<ng-container
			*ngIf="!component.showFirst && component.prototype.getType() === 'buttons'"
			[ansynEntryComponent]="{ component: component , mapId: mapId }">
		</ng-container>
	</ng-container>

</div>

<div class="description">
	<ansyn-popover *ngIf="overlay" [text]="overlayDescription"
				   [showOverflow]="isMinimalistViewMode"
				   [popDirection]="'bottom left'">
		<button class="share-link" (click)="copyOverlayDescription()">
			<span>{{overlayDescription | translate}}</span>
		</button>
	</ansyn-popover>
	<div *ngIf="!overlay">
		{{baseMapDescription | translate}}
	</div>
</div>

<ng-container *ngIf="!isMinimalistViewMode">
	<ng-container
		*ngFor="let component of entryComponents.status">
		<ng-container
			*ngIf="component.prototype.getType() === 'notification'"
			[ansynEntryComponent]="{ component: component , mapId: mapId }">
		</ng-container>
	</ng-container>
</ng-container>

