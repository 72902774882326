<div class="list color-selection-expand" *ngIf="show" [ngStyle]="getStyle()">

	<ansyn-color-picker
		[label]="'Fill' | translate"
		[color]="properties.fill"
		[active]="properties['fill-opacity'] && fillModeActive"
		[activeDisabled]="!(properties['stroke-opacity'])"
		[canToggle]="fillModeActive && strokeModeActive"
		(activeChange)="activeChange.emit({event: $event, label: 'fill'})"
		(colorChange)="colorChange.emit([{event: $event, label: 'fill'}, {event: $event, label: 'marker-color'}])">
	</ansyn-color-picker>

	<ansyn-color-picker
		[label]="'Stroke' | translate"
		[color]="properties.stroke"
		[active]="properties['stroke-opacity'] && strokeModeActive"
		[activeDisabled]="!(properties['fill-opacity'])"
		[canToggle]="fillModeActive && strokeModeActive"
		(colorChange)="colorChange.emit([{event: $event, label: 'stroke'}])"
		(activeChange)="activeChange.emit({event: $event, label: 'stroke'})">
	</ansyn-color-picker>

</div>
