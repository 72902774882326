<div class="angle-filter" *ngIf="_show">
	<div class="north-container"
		 [style.transform]="'rotate(' + mapRotation + 'deg)'">
		<div class="north">
			N
		</div>
	</div>
	<div class="circle">
		<p class="point">
			<i class="icon-pin-point"></i>
		</p>
		<div class="arrows">
		<span class="arrow" *ngFor="let angle of overlaysAngles"
			  (mousedown)="showOverlay($event, angle)"
			  (mouseover)="overlayHover(angle.overlay)"
			  (mouseleave)="overlayHover()"
			  [style.transform]="'rotate(' + angle.degreeFromPoint + 'deg)'"
			  [class.active]="isActive(angle.overlay) || this.hoverOverlay === angle.overlay.id"
		></span>
		</div>
	</div>
	<div class="rectangles">
		<button (click)="nextOverlay($event, false)"
				[disabled]="!overlay">
			<i class="icon-prev"></i>
		</button>
		<button (click)="nextOverlay($event, true)"
				[disabled]="!overlay">
			<i class="icon-next"></i>
		</button>
	</div>
</div>
