<div class="login-wrapper">
	<form class="login-form" #loginForm="ngForm" (ngSubmit)="login()">

		<h1 class="title">{{config?.title}}</h1>
		<div class="fields">

			<div class="field">
				<label for="username">User name</label>
				<input id="username" name="username" [(ngModel)]="username" (input)="hideTryAgainMsg()" required/>
			</div>

			<div class="field">
				<label for="password">Password</label>
				<input id="password" name="password" type="password" [(ngModel)]="password" (input)="hideTryAgainMsg()"
					   required/>
			</div>

			<div class="try-again" [class.show]="tryAgainMsg">
				The user name or password is incorrect. Try again.
			</div>

			<div class="remember-me">
				<input id="remember-me" name="remember-me" type="checkbox" [(ngModel)]="rememberMe"/>
				<label for="remember-me">Remember me</label>
			</div>

			<button [disabled]="loginForm.invalid">Log in</button>
		</div>
	</form>
</div>
