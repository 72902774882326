<div class="credentials" [ngClass]="'rtl' === ('direction' | translate) ? 'rtl' : ''">
	<div class="title">{{'Permissions' | translate}} | {{credentialsService?.user}}
		<span class="close-btn" (click)="closeWindow()">X</span></div>
	<div *ngIf="credentialsService?.error; else credentials">{{credentialsService?.error?.message | translate}}</div>
	<ng-template #credentials>
		<div class="authorized" *ngIf="hasAuthorized()">
			<i class="icon-credentials"></i>
			<span>{{'You have permissions for:' | translate}}</span>
			<div class="circles">
				<p *ngFor="let area of credentialsService?.authorizedAreas | slice:0:5" class="circle">{{area.Name}}</p>
			</div>
		</div>

		<div class="unauthorized" *ngIf="hasNoAuthorized()">
			<i class="icon-credentials-request"></i>
			<span>{{'You don\'t have permissions for:' | translate}}</span>
			<div class="circles">
				<p *ngFor="let area of credentialsService?.unauthorizedAreas | slice:0:5"
				   class="circle">{{area.Name}}</p>
			</div>
		</div>
		<div class="buttons">
		<span class="ask-permission"
			  (click)="credentialsService.openPermissionSite()"
			  [class.disabled]="!hasNoAuthorized()">
			{{'Ask for permissions' | translate}}
		</span>
			<span class="close-btn" (click)="closeWindow()">{{'Cancel' | translate }}</span>
		</div>
		<div class="guid">
			<span (click)="credentialsService.downloadGuide()">
				{{'permissions request guide' | translate}}
			</span>
			<span (click)="credentialsService.downloadGuide()">
		<i class="icon-download-guid"></i>
		</span>
		</div>
	</ng-template>
</div>
