<div class="combo-container" [ngClass]="[direction, color]"
	 [attr.tooltip-value]="comboBoxToolTipDescription || null"
	 [attr.tooltip-class]="direction"
	 [class.active]="optionsVisible"
>
	<button ansynComboBoxTrigger
			[ngClass]="buttonClass"
			type="button"
			[disabled]="disabled"
			[isActive]="optionsVisible"
			[withArrow]="withArrow"
			[icon]="icon"
			(click)="toggleShow()">
		<div class="render" [innerHTML]="renderSelected"></div>
		<div class="placeholder" *ngIf="placeholder" [class.float]="selected || optionsVisible"> {{ placeholder }}</div>
	</button>

	<div class="options-container scrolled"
         #optionsContainer [style.visibility]="optionsVisible ? 'visible' : 'hidden'"
		 tabindex="0" (blur)="onBlurOptionsContainer($event)">
		<ng-content></ng-content>
	</div>
</div>
