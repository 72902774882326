<div>
	<input type="checkbox"
		   [id]="id"
		   [(ngModel)]="value"
		   [disabled]="disabled"/>
</div>

<div class="ansyn-checkbox-text">
	<label [for]="id">
		<ng-content></ng-content>
	</label>
</div>
