<div class="overlay-overview" (dblclick)="onDblClick()">
	<header [ngClass]="'rtl' === ('direction' | translate) ? 'rtl' : ''">
		<div class="name">{{ sensorType | translate }} ({{sensorName | translate}})</div>
		<div>{{formattedTime}}</div>
	</header>
	<main>
		<img
            #img
			(error)="img.src = errorSrc"
			(load)="finishedLoadingImage()"
			[class.loading]="loadingImage"
			[style.transform]="'rotate(' + rotation + 'rad)'">
		<p class="error-text" *ngIf="img.src.includes(errorSrc)">{{"Preview is not available" | translate}}</p>
		<ansyn-loader [show]="loadingImage"></ansyn-loader>
	</main>
</div>
<div class="transparent" [id]="const.TRANSPARENT_DIV_ID"></div>
