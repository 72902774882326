<div *ngIf="!componentMode" class="rows">
	<div class="row top">
	<span [attr.tooltip-value]="selectedCaseName$ | async"
		  tooltip-class="bottom">
		{{selectedCaseName$ | async}}
	</span>
	</div>
	<hr/>
	<div class="bottom row">
		<div [attr.tooltip-value]="statusBarConfig?.toolTips?.overlayCount | translate"
			 tooltip-class="bottom">
			<i class="block-icon icon-status-bar-imagery-count"></i>
			<span>{{(overlaysCount$ | async) || 0}}</span>
		</div>
		<div class="share" (click)="copyLink()" *ngIf="statusBarConfig?.casePanelConfig?.active"
			 [attr.tooltip-value]="statusBarConfig?.toolTips?.share | translate"
			 tooltip-class="bottom-left">
			<i class="icon-share"></i>
			<span>{{'Share' | translate}}</span>
		</div>
	</div>
</div>
<div *ngIf="componentMode" class="component-mode">
	<div [attr.tooltip-value]="statusBarConfig?.toolTips?.overlayCount | translate"
		 tooltip-class="bottom">
		<i class="block-icon icon-status-bar-imagery-count"></i>
		<span>{{(overlaysCount$ | async) || 0}}</span>
	</div>
</div>
