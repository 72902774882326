<div class="container" *ngIf="!metadataFromState?.shouldBeHidden()">

	<div class="title-row">

		<div class="filter-title" [ngClass]="'rtl' === ('direction' | translate) ? 'rtl' : ''">
			<span class="title" [class.title-disabled]="showOnlyFavorite">{{ filter.displayName | translate }}</span>

			<span
				*ngIf="metadataFromState && metadataFromState.collapse && metadataFromState.isFiltered() && !showOnlyFavorite"
			  	class="filtered">{{"(filtered)" | translate}}</span>
		</div>

		<div class="filter-title-buttons">
			<div *ngIf="!showOnlyFavorite && (metadataFromState && metadataFromState.hasResults())" class="show-all">
				<span (click)="showAll()">{{'Show all' | translate}}</span>
			</div>
		</div>
	</div>

	<div *ngIf="(isLoading$ | async); else filterBody">
		<ansyn-animated-ellipsis [text]="'Loading' | translate"
								 [rtl]="'rtl' === ('direction' | translate)"></ansyn-animated-ellipsis>
	</div>

	<ng-template #filterBody>
		<div class="fields scrolled " *ngIf="!showOnlyFavorite" [@fieldsTrigger]="!metadataFromState.collapse">
			<div *ngIf="metadataFromState" [ngSwitch]="metadataFromState?.type">

				<ansyn-enum-filter-container
					*ngSwitchCase="'Enum'"
					[isLongFiltersList]="isLongFiltersList"
					[metadata]="metadataFromState"
					[filtersSearchResult]="filtersSearchResults[filter.displayName]"
					(onMetadataChange)="onMetadataChange($event)">
				</ansyn-enum-filter-container>

				<ansyn-slider-filter-container
					*ngSwitchCase="'Slider'"
					[metadata]="metadataFromState"
					(onMetadataChange)="onMetadataChange($event)">
				</ansyn-slider-filter-container>

				<ansyn-boolean-filter-container
					*ngSwitchCase="'Boolean'"
					[metadata]="metadataFromState"
					[customData]="filter.customData"
					(onMetadataChange)="onMetadataChange($event)">
				</ansyn-boolean-filter-container>

				<ansyn-array-filter-container
					*ngSwitchCase="'Array'"
					[metadata]="metadataFromState"
					(onMetadataChange)="onMetadataChange($event)"
					[filtersSearchResult]="filtersSearchResults[filter.displayName]">
				</ansyn-array-filter-container>
			</div>
		</div>

		<div class="show-more-less" *ngIf="!isGotSmallListFromProvider && !showOnlyFavorite && !metadataFromState.collapse">
			<span (click)="toggleShowMoreLess()">{{(!isLongFiltersList ? 'SHOW MORE' : 'SHOW LESS') | translate}}</span>
		</div>

	</ng-template>


</div>
