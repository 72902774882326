<div class="save-case">
	<button (click)="close()" class="close" type="button">
		<span>×</span>
	</button>

	<form #f="ngForm" class="save-case-form" (ngSubmit)="onSubmitCase()">
		<ansyn-input
			[(ngModel)]="caseName"
			select
			name="name"
			[required]="true"
			[label]="'CaseName' | translate">
		</ansyn-input>

		<div class="bottom-btns">
			<button type="button" (click)="close()">{{'Cancel' | translate}}</button>
			<button type="submit" [disabled]="f.invalid">{{'OK' | translate}}</button>
		</div>
	</form>
</div>
