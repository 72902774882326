<!-- Geo filter -->
<div class="box" id="geoPreSearch" [class.component]="componentMode">
	<button ansynComboBoxTrigger
			(click)="toggleExpander('LocationPicker')"
			[attr.tooltip-value]="statusBarConfig?.toolTips?.geoFilter | translate"
			tooltip-class="bottom"
			[class.active]="isActive('LocationPicker')"
			[isActive]="isActive('LocationPicker')"
			[withArrow]="false"
			icon="icon icon-status-bar-geo"
			class="button">
		<div [class.component]="componentMode">
			<span class="title">{{geoFilterTitle | translate}} </span>
			<span *ngIf="!componentMode" class="coordinates"> ({{geoFilterCoordinates}})</span>
		</div>
	</button>

	<ansyn-location-picker
		*ngIf="isActive('LocationPicker')"
		[geoFilter]="geoFilterTitle">
	</ansyn-location-picker>
</div>

<!-- Time -->
<div class="box timePickerBox"
	 (ansynClickOutside)="shouldCloseTimePicker()"
	 [extraClass]="'owl-dt-container presets'"
	 [trigger]="timePickerTrigger?.optionsTrigger?.nativeElement"
>
	<button ansynComboBoxTrigger
			#timePickerTrigger
			(click)="openTimePickerPreset()"
			[attr.tooltip-value]="statusBarConfig?.toolTips?.timeFilterEdit | translate"
			tooltip-class="bottom"
			[class.active]="isActive('TimePickerPreset')"
			[isActive]="isActive('TimePickerPreset')"
			[withArrow]="false"
			icon="icon icon-status-bar-time"
			class="button">

		<span #timePickerTitleFrom
			  contenteditable
			  [class.error]="timeError?.from"
			  class="title">{{timeSelectionTitle?.from}}</span>
		{{'&nbsp;&nbsp;-&nbsp;&nbsp;'}}
		<span #timePickerTitleTo
			  contenteditable
			  [class.error]="timeError?.to"
			  class="title">{{timeSelectionTitle?.to}}</span>
	</button>

	<ansyn-timepicker-presets *ngIf="isActive('TimePickerPreset')"
							  (hideMe)="toggleExpander('TimePickerPreset')"
							  (openTimePicker)="toggleExpander('TimePicker')"></ansyn-timepicker-presets>

	<ansyn-timepicker *ngIf="isActive('TimePicker')"
					  [timeRange]="timeRange"
					  (closeTimePicker)="toggleExpander('TimePicker')"
	></ansyn-timepicker>
</div>

<!-- data input filter-->
<div class="box" id="dataInputsPreSearch">
	<button ansynComboBoxTrigger
			#trigger
			(click)="toggleExpander('DataInputs')"
			icon="icon icon-input-filter"
			[isActive]="isActive('DataInputs')"
			[attr.tooltip-value]="statusBarConfig?.toolTips?.dataInputFilter | translate"
			tooltip-class="bottom"
			[class.active]="isActive('DataInputs')"
			[withArrow]="false"
			class="button">
		<span class="title">{{'Type' | translate}} - {{ dataInputFilterTitle | translate }}</span>
	</button>

	<ansyn-tree-view
		*ngIf="isActive('DataInputs')"
		[trigger]="trigger?.optionsTrigger?.nativeElement"
		(ansynClickOutside)="toggleExpander('DataInputs')"
		(closeTreeView)="toggleExpander('DataInputs')"
		class="ansyn-tree-view"></ansyn-tree-view>
</div>
