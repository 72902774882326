<div class="slider-wrapper">

	<div class="left-side">
		<div class="upper-row">
			<div class="min">
				{{metadata.min}}
			</div>
			<div class="range">
				{{getMinRangeValue(realRange[0]) | translate}} - {{getMaxRangeValue(realRange[1]) | translate}}
			</div>
			<div class="max">
				{{metadata.max}}
			</div>
		</div>

		<p-slider class='slider' [ngModel]="rangeValues" [range]="true" (onSlideEnd)="handleChange($event)"
				  [min]="factor * metadata.min" [max]="factor * metadata.max"></p-slider>
	</div>

	<ansyn-filter-counter [count]="metadata.count" [filteredCount]="metadata.filteredCount"></ansyn-filter-counter>

</div>
