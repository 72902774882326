import { Component } from '@angular/core';

@Component({
	selector: 'ansyn-cases',
	templateUrl: './cases.component.html',
	styleUrls: ['./cases.component.less']
})

export class CasesComponent {
}
