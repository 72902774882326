<div class="buttons-group">
	<button ansynButton type="button" (click)="showEditCaseModal()">
		{{'Add Case' | translate}}
	</button>
	<button ansynButton type="button" (click)="showSaveCaseModal()">
		{{'Save As' | translate}}
	</button>
	<button ansynButton type="button" [disabled]="isDefaultCaseId" (click)="manualSave()">
		{{'Save' | translate}}
	</button>
</div>
