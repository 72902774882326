<form [formGroup]="form">
	<div class="inputs-container">
		<ng-container *ngFor="let attribute of attributes">
			<ansyn-dynamic-attribute-control [attribute]="attribute" [form]="form" (onChange)="submit()"></ansyn-dynamic-attribute-control>
		</ng-container>
	</div>
	<div class="clear-btn-container">
		<button mat-flat-button (click)="clear()">{{'Clear All' | translate}}</button>
	</div>
</form>
