import { Component } from '@angular/core';

@Component({
	selector: 'ansyn-placeholder',
	template: '',
	styleUrls: []
})

export class PlaceholderComponent {

	constructor() {
	}

}
