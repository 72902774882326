<mat-form-field class="example-full-width">
	<input matInput
           #input
		   [maxLength]="maxLength"
		   [placeholder]="label"
		   [autocomplete]="autocomplete"
		   (blur)="onBlur()"
		   [disabled]="disabled"
		   [(ngModel)]="value">
</mat-form-field>
