<div class="tools-menu-item-container">
	<div>
		<button tooltip-class="right"
				[attr.tooltip-value]="'Shadow mouse cursor' | translate"
				(click)="toggleShadowMouse()"
				[class.mode-on]="onShadowMouse && !shadowMouseDisabled"
				[disabled]="shadowMouseDisabled">
			<i class="icon-shadow-mouse"></i>
		</button>
	</div>

	<div>
		<button tooltip-class="right"
				[attr.tooltip-value]="'Go To' | translate"
				(click)="toggleSubMenu(subMenuEnum.goTo)"
				[class.active]="isExpand(subMenuEnum.goTo) && !isGeoOptionsDisabled"
				[disabled]="isGeoOptionsDisabled">
			<i class="icon-tools-go-to"></i>
		</button>
		<ansyn-go-to [disabled]="isGeoOptionsDisabled"></ansyn-go-to>
	</div>

	<div class="display-overlay-visualizer">
		<button tooltip-class="right"
				[attr.tooltip-value]="'Overlays Footprint' | translate"
				(click)="toggleDisplayFootprints()"
				[disabled]="isGeoOptionsDisabled"
				[class.mode-on]="displayModeOn && !isGeoOptionsDisabled"
				[class.active]="displayModeOn && !isGeoOptionsDisabled">
			<i class="icon-frame-mode"></i>
		</button>

		<!--<ansyn-overlays-display-mode-->
			<!--[disabled]="isGeoOptionsDisabled"-->
			<!--[expand]="isExpand(subMenuEnum.overlays) && !isGeoOptionsDisabled"-->
			<!--[(modeOn)]="displayModeOn">-->
		<!--</ansyn-overlays-display-mode>-->
	</div>

	<div class="user-annotations" [class.active]="isExpand(subMenuEnum.annotations)">
		<button tooltip-class="right"
				[attr.tooltip-value]="'Annotations' | translate"
				[class.active]="isExpand(subMenuEnum.annotations) && !isGeoOptionsDisabled"
				(click)="toggleSubMenu(subMenuEnum.annotations, $event)"
				[disabled]="!(isActiveAnnotationLayer$ | async) || isGeoOptionsDisabled">
			<span class="icon-annotations"></span>
		</button>
		<ansyn-annotations-control
			[isGeoOptionsDisabled]="isGeoOptionsDisabled"
			[expand]="isExpand(subMenuEnum.annotations) && !isGeoOptionsDisabled"
			(hideMe)="toggleSubMenu(subMenuEnum.annotations)"
		></ansyn-annotations-control>

	</div>

	<div class="distance-measure-tool">
		<button tooltip-class="right"
				[attr.tooltip-value]="'Measure Distance' | translate"
				(click)="toggleMeasureDistanceTool()"
				[disabled]="isGeoOptionsDisabled"
				[class.mode-on]="onMeasureTool && !isGeoOptionsDisabled">
			<i class="icon-tools-ruler"></i>
		</button>
	</div>

	<div class="export-maps-tool">
		<button tooltip-class="right"
				[attr.tooltip-value]="'Export maps' | translate"
				(click)="toggleExportMapsDialog()">
			<i class="icon-export-maps"></i>
		</button>
	</div>

</div>
