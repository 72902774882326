<div class="back-to-world-view" *ngIf="overlay">
	<button (click)="backToWorldView()"
			class="small-icon-button"
			[class.menu-pinned]="isPinned"
			tooltip-class="bottom right"
			[attr.tooltip-value]="'Back to base map' | translate">
		<i class="icon-back-to-base-map"></i>
		{{'Back to base map' | translate}}
	</button>
</div>
