<div class="annotation-label-form">
	<button
		class="drag-annotation"
		[disabled]="label?.text.length === 0"
		(click)="onTranslateClick.emit()"
		[attr.tooltip-value]="'Move Label' | translate" tooltip-class="bottom">
		<i class="icon icon-drag-annotations" [class.active]="translateOn"></i>
	</button>
	<form class="annotation-label-edit"
	  (ngSubmit)="labelInput.blur()">
	<mat-form-field class="label-font-size">
		<mat-select [placeholder]="'Label size' | translate"
					[ngModel]="labelSize"
					(ngModelChange)="onChangeSize.emit($event)"
		name="labelSize">
			<mat-option *ngFor="let size of optionalSize" [value]="size" >{{size}}</mat-option>
		</mat-select>
	</mat-form-field>
	<mat-form-field class="label-form-field">
		<input matInput
			   type="text"
               #labelInput
			   autofocus
			   [placeholder]="'Label' | translate"
			   name="EditLabel"
			   [ngModel]="label.text"
			   (ngModelChange)="onChangeText.emit($event)">
	</mat-form-field>
</form>
</div>
