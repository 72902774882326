<div class="collection">
	<ansyn-slider-checkbox [checked]="onlyFavorite" (checkedChange)="showOnlyFavorites($event)"
						   [disabled]="disableShowOnlyFavoritesSelection">
		{{'Show only favorites' | translate}}
	</ansyn-slider-checkbox>

	<ansyn-input
		[ngModel]="filtersSearch$ | async"
		(ngModelChange)="setFiltersSearch($event)"
		[white]="true"
		name="search"
		id="ansyn-task-name"
		class="margin"
		[label]="'Search' | translate">
	</ansyn-input>

	<div class="allFilters scrolled">
		<ng-container *ngIf="filters?.length; else noFiltersFound">
			<ansyn-filter-container
				*ngFor="let filter of filters"
				[filter]="filter">
			</ansyn-filter-container>
		</ng-container>

		<ng-template #noFiltersFound>
			<p class="noFiltersFound">
				{{"Couldn't find any results." | translate}}
			</p>
		</ng-template>
	</div>

	<ansyn-manual-removed-overlays>
	</ansyn-manual-removed-overlays>

</div>
