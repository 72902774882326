<div class="status-timeline-container" #footerWrapper>
	<button
		*ngIf="config?.isFooterCollapsible"
		class="hide-menu"
		(click)="toggle()"
		[attr.tooltip-value]="minimizeText | translate"
		tooltip-class="top">
		<i class="fa fa-caret-down" [class.fa-caret-up]="collapse"></i>
	</button>

	<div class="app-timeline-container">
		<ansyn-overlay-navigation-bar></ansyn-overlay-navigation-bar>
		<ansyn-overlays-container></ansyn-overlays-container>
	</div>
</div>

