<div *ngIf="show">
	<div class="buttons">
		<button class="small-icon-button"
				[attr.tooltip-value]="'Zoom in' | translate"
				tooltip-class="'left'"
				(click)="zoomIn()">
			+
		</button>
	</div>

	<div class="buttons">
		<button class="small-icon-button"
				[attr.tooltip-value]="'Zoom out' | translate"
				tooltip-class="'left'"
				(click)="zoomOut()">
			-
		</button>
	</div>

	<div class="buttons" *ngIf="showOne2One">
		<button class="small-icon-button"
				(click)="one2one()">
			1:1
		</button>
	</div>
</div>
