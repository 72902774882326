<div class="download" [ngClass]="'rtl' === ('direction' | translate) ? 'rtl' : ''">

	<h4 [title]="layer?.name"><i
		class="icon-layer-export"></i>&nbsp;{{'Export' | translate}}&nbsp;"<span>{{ layer?.name }}</span>"</h4>

	<div class="export-buttons">
		<button class="format" (click)="downloadGeojson()">GeoJSON</button>
		<button class="format" (click)="downloadKml()">KML</button>
	</div>
</div>
