<form (reset)="control.setValue('')" (submit)="onSubmit()" (keyup.enter)="onSubmit()">
	<input type="text" [placeholder]="'Search place or coordinates' | translate"
		   [formControl]="control"
		   [matAutocomplete]="auto">
	<mat-autocomplete #auto="matAutocomplete" panelWidth="fit" autoActiveFirstOption>
		<mat-option *ngIf="loading;" disabled>
			{{'loading results' | translate}}
		</mat-option>
		<mat-option *ngIf="error" disabled>
			{{'No Results' | translate}}
		</mat-option>
		<mat-option *ngFor="let location of locations"
					(onSelectionChange)="goToLocation(location.point)"
					[value]="location.name">
			{{location.name}}
		</mat-option>


	</mat-autocomplete>
	<button type="submit"><i class="fa fa-search"></i></button>
	<button type="reset" [hidden]="!control.value"><i class="fa fa-times"></i></button>
</form>
