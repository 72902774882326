import { Component, Input, ChangeDetectionStrategy, ViewEncapsulation, Output, EventEmitter, OnInit } from '@angular/core';
import { AttributeBase } from '../../models/attribute-base';
import { FormGroup } from '@angular/forms';
import { ControlType } from '../../models/control-type.enum';
import { IKeyValuePair } from '../../models/key-value.interface';
import { MultiChoiceAttribute } from '../../models/multi-choice-attribute';
import { Observable, EMPTY } from 'rxjs';

@Component({
	selector: 'ansyn-dynamic-attribute-control',
	templateUrl: './dynamic-attribute-control.component.html',
	styleUrls: ['./dynamic-attribute-control.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class DynamicAttributeControlComponent implements OnInit {
	controlType = ControlType;
	selectedOptions$: Observable<IKeyValuePair<string>[]>;

	@Input() attribute: AttributeBase<any>;
	
	// tslint:disable-next-line:no-input-rename
	@Input('form') attributesForm: FormGroup;

	@Output() onChange = new EventEmitter();

	get isValid() {
		return this.attributesForm.controls[this.attribute.key].valid;
	}

	ngOnInit() {
		this.selectedOptions$ = this.attribute instanceof MultiChoiceAttribute ?
			this.attribute.selectedOptions$ :
			EMPTY;
	}

	selectOption(option: IKeyValuePair<string>) {
		(this.attribute as MultiChoiceAttribute).addSelectedOption(option);
		this.onChange.emit();
	}

	removeOption(option: IKeyValuePair<string>) {
		(this.attribute as MultiChoiceAttribute).removeSelectedOption(option);
		this.onChange.emit();
	}
}
