<div *ngIf='long && isVisible'
	 class="mouse-coordinate"
	 [ngSwitch]="floationgSystem">

	<ansyn-geo-wgs-holder *ngSwitchCase="'WGS84'"
					  [lat]="lat"
					  [long]="long"
					  [height]="height"></ansyn-geo-wgs-holder>
	<ansyn-utm-holder *ngSwitchCase="'UTM'"
					  [x]="long"
					  [y]="lat"
					  [zone]="zone"
					  [height]="height"></ansyn-utm-holder>
	&nbsp;{{floatingPositionSuffix}}
</div>

