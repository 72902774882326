<div class="menu-wrapper" #menuWrapper>
	<div class="menu" #menu [class.expanded]="expand">
		<div class="menu-content">
			<div class="menu-items">
				<div
					*ngFor="let menuItem of topMenuItemsAsArray$ | async"
					class="menu-btn"
					[class.active]="isActive(menuItem.name)"
					(click)="toggleItem(menuItem.name)"
				>
					<i [ngClass]="menuItem.iconClass"></i>
					<span
						class="badge count-badge"
						[hidden]="hideBadge(menuItem.badge, menuItem.name)"
						>{{ menuItem.badge }}</span
					>
					{{ menuItem.name | translate }}
				</div>
			</div>
			<div
				*ngFor="let menuItem of bottomMenuItemsAsArray$ | async"
				class="menu-btn bottom"
				[class.draw-user-attention]="
					isUserFirstEntrance || !doesUserHaveCredentials
				"
				[class.active]="isActive(menuItem.name)"
				(click)="toggleItem(menuItem.name, true)"
			>
				<i [ngClass]="menuItem.iconClass"></i>
				<span
					class="badge bottom"
					[hidden]="!isUserFirstEntrance && doesUserHaveCredentials"
				>
				</span>
				{{ menuItem.name | translate }}
			</div>
			<div class="logo">
				<div
					class="mode"
					*ngIf="menuConfig?.mode"
					[style.color]="menuConfig.color"
					[style.background]="menuConfig.background"
					[style.border]="'1px sollid' + menuConfig.color"
				>
					{{ menuConfig.mode }}
				</div>
				<img
					[src]="menuConfig.path"
					[alt]="version"
					[title]="version"
					(click)="resetApp()"
				/>
			</div>
		</div>

		<button
			*ngIf="menuConfig?.isCollapsible"
			class="hide-menu"
			(click)="toggleCollapse()"
			[attr.tooltip-value]="minimizeText | translate"
			tooltip-class="right"
		>
			<i class="fa fa-caret-left" [class.fa-caret-right]="collapse"></i>
		</button>
	</div>

	<div
		class="container"
		#container
		[@expand]="expand"
		(@expand.done)="onExpandDone()"
		(@expand.start)="onExpandStart()"
	>
		<!--<button class="close" (click)="closeMenu()">×</button>-->
		<button
			class="pin"
			(click)="toggleIsPinned()"
			[attr.tooltip-value]="pinText | translate"
			tooltip-class="right"
		>
			<i [ngClass]="isPinned ? 'icon-pin' : 'icon-unpin'"></i>
		</button>
		<ng-template #componentElem></ng-template>
	</div>
</div>
