import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'button[ansynButton]',
	templateUrl: './ansyn-button.component.html',
	styleUrls: ['./ansyn-button.component.less']
})
export class AnsynButtonComponent implements OnInit {

	constructor() {
	}

	ngOnInit() {
	}

}
