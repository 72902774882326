<div class="results-table">
	<div class="header">
		<div class="header-title">
			{{'Search results' | translate}}
		</div>
		<div class="box">
			<i class="block-icon icon-status-bar-imagery-count"></i>
			<span class="overlay-count">{{overlays.length || 0}}</span>
		</div>
	</div>
	<div class="results-table-head">
		<div class="results-table-head-row">
			<div class="results-table-head-row-data">
				<div (click)="sortOverlays(header)" *ngFor="let header of tableHeaders" class="results-table-head-col  {{header.headerName}}">
					{{header.headerName | translate}}
					<i class="icon icon-arrow" [@isDescending]="header.isDescending" [class.active]="sortedBy === header.headerData"></i>
				</div>
			</div>

		</div>
	</div>

	<div #table class="results-table-body scrolled"  (ansynInfiniteScroll)="loadResults()" *ngIf="overlays">

		<div class="results-table-body-row"
			 *ngFor='let overlay of overlays  | slice:start:end'
			 [id]="overlay.id"
			 (click)="openOverlay(overlay.id)"
			 (mouseenter)="onMouseOver($event, overlay.id)"
			 (mouseleave)="onMouseOut()"
			 [class.selected]="overlayIds.includes(overlay.id)">

			<hr>

			<div class="results-table-body-row-data">
				<hr>

				<div class="results-table-case-name-col overlay-time">
					{{overlay.date | ansynDate}}
				</div>

				<div class="results-table-body-col source-type">
					{{overlay.sensorName | translate}}
				</div>

				<div class="results-table-body-col overlay-type">
					<i class="icon icon-{{overlay.icon}}"></i>
				</div>

			</div>
		</div>

	</div>
</div>
