<div class="app animations" [ngClass]="(isPinnedClass$ | async)">
	<div class="menu">
		<ansyn-menu [version]="version" [animatedElement]="animatedElementHorizontal"></ansyn-menu>
	</div>

	<div class="status" [class.expand]="(isMenuCollapse$ | async)" [class.hide-status-bar]="(hideStatus$ | async)">
		<ansyn-status-bar></ansyn-status-bar>
	</div>

	<div class="layer" #animatedElementHorizontal [class.hide-status-bar]="(hideStatus$ | async)">

		<div class="maps-container" #animatedElementVertical>
			<ansyn-imageries-manager></ansyn-imageries-manager>
			<ansyn-context-menu *ngIf="renderContextMenu"></ansyn-context-menu>
		</div>

		<ansyn-footer
			[activeMap]="(activeMap$ | async)"
			[animatedElement]="animatedElementVertical"
		></ansyn-footer>
	</div>
</div>

<ansyn-overlay-overview></ansyn-overlay-overview>

<ng-container *ngIf="!componentMode">
	<router-outlet></router-outlet>
</ng-container>

<!-- App overlays -->
<ansyn-toast [duration]="4"></ansyn-toast>

