<div class="dynamic-attribute-control-component"
	*ngIf="!!attributesForm"
	[formGroup]="attributesForm">
	<ng-container *ngIf="attribute.type === controlType.MultipleChoices; else attributeTypeTemplate">
		<ansyn-multi-choice-attribute
			[label]="attribute.label"
			[optionsList]="attribute.options"
			[selectedOptions]="selectedOptions$ | async"
			(onSelectOption)="selectOption($event)"
			(onRemoveOption)="removeOption($event)">
		</ansyn-multi-choice-attribute>
	</ng-container>
	<ng-template #attributeTypeTemplate>
		<mat-form-field>
			<mat-label>{{ attribute.label | translate }}</mat-label>
			<ng-container [ngSwitch]="attribute.type">
				<textarea *ngSwitchCase="controlType.FreeText"
					matInput
					matTextareaAutosize
					matAutosizeMinRows="2"
					[formControlName]="attribute.key"
					[id]="attribute.key"
					(input)="onChange.emit()">
				</textarea>
				<mat-select *ngSwitchCase="controlType.SingleChoice"
					[id]="attribute.key"
					[formControlName]="attribute.key"
					[value]="attribute.value"
					(selectionChange)="onChange.emit()"
					panelClass="dynamic-attribute-control-component-dark-panel">
					<mat-option *ngFor="let opt of attribute.options" [value]="opt.value">{{ opt.value | translate }}</mat-option>
				</mat-select>
				<input *ngSwitchCase="controlType.Number"
					matInput
					[formControlName]="attribute.key"
					type="number"
					[id]="attribute.key"
					(input)="onChange.emit()">
				<input *ngSwitchDefault
					matInput
					[formControlName]="attribute.key"
					type="text"
					[id]="attribute.key"
					(input)="onChange.emit()">
			</ng-container>
		</mat-form-field>
	</ng-template>
</div>
