<div class="layer">

	<div class="layer-name">
		<ansyn-checkbox
			class="regular-checkbox"
			[id]="'checkbox' + layer?.id"
			(ngModelChange)="onCheckboxClicked($event)"
			[ngModel]="isChecked">
			{{layer?.name}}
		</ansyn-checkbox>

		<span class="only-filter" (click)="selectOnly()" [hidden]="showOnly">{{'only' | translate}}</span>
	</div>

	<ng-content></ng-content>

</div>
