<form dir="rtl" #f="ngForm" (ngSubmit)="onSubmit()" style="margin-top: 15px">

	<div class=" small-padding-left">{{'Status' | translate}}</div>
	<div class="big-font wide-underline small-padding-left margin">{{taskStatus | translate}}</div>

	<ansyn-input
		[(ngModel)]="taskName"
		(input)="onTaskNameChange()"
		required
		[white]="true"
		name="name"
		id="ansyn-task-name"
		class="margin"
		[label]="'TasksName' | translate">
	</ansyn-input>

	<ansyn-combo-box
		[(ngModel)]="algName"
		(ngModelChange)="onAlgorithmNameChange()"
		required
		name="algorithm"
		[color]="'transparent'"
		[placeholder]="'Select algorithm' | translate"
		class="margin"
	>
		<ansyn-combo-box-option *ngFor="let algName of algNames" [value]="algName">
			{{ algName | translate }}
		</ansyn-combo-box-option>
	</ansyn-combo-box>

	<hr class="big-font margin">

	<div class="small-padding-left margin">{{'Area of interest' | translate}}</div>

	<div class="small-padding-left margin flex-row">
		<span>{{'Pin Location' | translate}}</span>
		<button type="button" class="pin" [class.done]="currentTaskRegion$ | async" (click)="startDrawMode()">
			<i class="icon-edit"></i>
		</button>
	</div>

	<div class="big-font wide-underline margin"></div>

	<div class="two-columns-table">
		<div>
			<div>{{'Imagery input' | translate}}</div>
			<div>{{'Master image' | translate}}</div>
		</div>

		<div>
			<ansyn-radio
				[(ngModel)]="whichOverlays"
				[value]="'case_overlays'"
				name="whichOverlays1"
				disabled
			>{{'Case overlays' | translate}}
			</ansyn-radio>
			<div>{{'Active overlay' | translate}}</div>
		</div>

		<div>
			<ansyn-radio
				[(ngModel)]="whichOverlays"
				[value]="'favorite_overlays'"
				name="whichOverlays2"
			>{{'Favorite overlays' | translate}}
			</ansyn-radio>
		</div>


		<div>
			<ansyn-radio
				[(ngModel)]="whichOverlays"
				[value]="'displayed_overlays'"
				name="whichOverlays3"
				disabled
			>{{'Displayed overlays' | translate}}
			</ansyn-radio>
		</div>
	</div>

	<div [class.invisible]="!algName">
		<div>{{'Note: this algorithm is only available for' | translate}} {{algName ? algorithms[algName].maxOverlays : '??'}} {{'overlays' | translate}}.
		</div>
		<div>{{'Supported sensors' | translate}}: {{supportedSensor.join(',')}}</div>
	</div>

	<div class="big-font wide-underline margin"></div>

	<div class="margin">{{'The results will be available for download when the task is completed'  | translate}}</div>

	<div class="run-bar margin">
		<button ansynButton
				[disabled]="f.invalid || hasError() || !(currentTaskRegion$ | async) || !(isNewTask$ | async)">{{'Run Task' | translate}}
		</button>
		<div *ngIf="algName">{{'Time Estimation' | translate}}: {{timeEstimation$ | async}}{{'m' | translate}}</div>
	</div>

	<div *ngIf="hasError()" class="errors">{{errorMsg | translate}}</div>

</form>

<ansyn-loader id="ansyn-task-form-loader" [show]="loading"></ansyn-loader>
