import { Component } from '@angular/core';

@Component({
	selector: 'ansyn-overlays-container',
	templateUrl: './overlays-container.component.html',
	styleUrls: ['./overlays-container.component.less']
})

export class OverlaysContainerComponent {
}


