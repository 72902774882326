<div
	*ngIf="show"
	[class.measure]="show"
	[style.direction]="'rtl' === ('direction' | translate) ? 'rtl' : 'ltr'">
	<p>{{'Measure Distance' | translate}}</p>

	<div class="buttons">
		<button id="showLayerBtn" class="bold-on-hover"
				[class.active]="measureData?.isLayerShowed"
				(click)="toggleShowLayer()">
			{{'Hide / Show Layer' | translate}}
		</button>

		<button id="startMeasureBtn" class="bold-on-hover"
				[class.active]="measureData?.isToolActive"
				(click)="toggleMeasureToolActivation()">
			{{'Stop / Start Measure' | translate}}
		</button>

		<button id="removeSingleMeasureBtn" class="bold-on-hover"
				[class.active]="measureData?.isRemoveMeasureModeActive"
				(click)="toggleRemoveSingleMeasure()">
			{{'Remove One Measurement' | translate}}
		</button>

		<button id="clearBtn" class="bold-on-hover"
				(click)="clearMeasure()">
			{{'Clear' | translate}}
		</button>

		<button id="doneBtn" class="bold-on-hover"
				(click)="done()">{{'Done' | translate}}
		</button>
	</div>
</div>

