import { Component } from '@angular/core';

@Component({
	selector: 'ansyn-callback',
	templateUrl: './callback.component.html',
	styleUrls: ['./callback.component.less']
})
export class CallbackComponent {

}
