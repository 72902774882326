<div class="edit-layer" [ngClass]="'rtl' === ('direction' | translate) ? 'rtl' : ''">
	<form class="edit-layer-form" (ngSubmit)="removeLayer()">
		<h4 [title]="layer?.name"><i
			class="icon-delete"></i>&nbsp;{{'Delete' | translate}}&nbsp;"<span>{{ layer?.name }}</span>"?</h4>

		<div class="bottom-btns">
			<button type="button" (click)="closeModal()">{{'Cancel' | translate}}</button>
			<button>{{'OK' | translate}}</button>
		</div>
	</form>
</div>
