<div class="removed-show-hide">
	<div class="show-hide-filtered-overlays">
		<ansyn-slider-checkbox [checked]="!removedOverlaysVisibility"
							   (checkedChange)="showRemoved()"></ansyn-slider-checkbox>
		<div class="show-hide-filtered-overlays-text">
			<span>{{'Show manually filtered overlays' | translate}} - {{removedOverlaysCount}}</span>
		</div>
	</div>
	<div class="reset-list">
		<span (click)="showAll()">{{'Reset list' | translate}}</span>
	</div>
</div>
