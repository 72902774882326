<button class="close" (click)="close()"><i class="icon-close"></i></button>

<form #form="ngForm" (ngSubmit)="submitGoTo()">

	<ansyn-geo-wgs
		name="geoWgs84"
		[(ngModel)]="inputs.geoWgs84"
		(ngModelChange)="convert($event, geoWgs84)"
		(copyToClipBoardHandler)="copyToClipBoard($event)"
		required>
	</ansyn-geo-wgs>

	<ansyn-utm
		title="UTM ED50"
		name="utmEd50"
		[notification]="notification"
		[(ngModel)]="inputs.utmEd50"
		(ngModelChange)="convert($event, utmEd50)"
		(copyToClipBoardHandler)="copyToClipBoard($event)"
		required>
	</ansyn-utm>

	<ansyn-utm
		title="UTM WGS84"
		name="utmWgs84"
		[(ngModel)]="inputs.utmWgs84"
		(ngModelChange)="convert($event, utmWgs84)"
		(copyToClipBoardHandler)="copyToClipBoard($event)"
		required>
	</ansyn-utm>

	<div class="buttons-area">

		<button ansynButton type="submit" [disabled]="form.invalid || disabled">{{'Go' | translate}}</button>
		<button [disabled]="disabled" class="pin-location" type="button" (click)="togglePinLocation()"
				[class.active]="pinLocationMode">
			<i class="icon-pin"></i>
			<span>{{'Pin location' | translate}}</span>
		</button>
	</div>

</form>
