<nav class="menu">

	<button class="menu-open-button" (mousedown)="isClick($event) && setPinPoint()" id="contextGeoFilter">
		<span class="pointer icon-pin-point">

		</span>
	</button>

	<div class="buttons-area">
		<button
			*ngFor="let data of overlayButtons"
			class="menu-item"
			[ngClass]="data.name"
			[disabled]="isDisabled(data.subList)"
			(mousedown)="isClick($event) && data.action($event)"
			tooltip-class="top"
			[attr.tooltip-disabled-value]="data.disabledToolTip | translate">
			<i class="icon-context-menu-{{data.name}}"></i>
			<span>{{data.name | translate  | titlecase}}</span>
			<div class="sub-buttons-list" *ngIf="asList(data.subList)">
				<button
					*ngFor="let filter of this[data.subList]"
					(mousedown)="isClick($event) && data.action($event, filter)"
					tooltip-class="top"
					[attr.tooltip-value]="filter | translate">
					<span dir="auto">{{getSensorType(filter) | translate}}</span>
				</button>
			</div>
		</button>
	</div>
</nav>
