<div
	[attr.tooltip-value]="geoRegisteredProperties.tooltipNorth | translate"
	tooltip-class="left">
	<div class="tooltip-container"
		 [style.cursor]="isRotating ? '-webkit-grabbing' : '-webkit-grab'"
		 [style.transform]="'rotate(' + this[geoRegisteredProperties.rotatePointer] + 'rad)'"
		 (mousedown)="startRotating($event)">
		<div class="north" [style.color]="geoRegisteredProperties.color"> {{ geoRegisteredProperties.letter }}</div>
	</div>
</div>

<div class="img-container"
	 [attr.tooltip-value]="geoRegisteredProperties.tooltip | translate"
	 tooltip-class="bottom left">
	<img
		tabindex="0"
		#northImg
		[src]="geoRegisteredProperties.compass"
		(click)="toggleNorth()"
		(mousedown)="stopPropagation($event)"
		[style.transform]="'rotate(' + this[geoRegisteredProperties.rotatePointer] + 'rad)'"
		[style.cursor]="isRotating ? '' : 'pointer'"/>
</div>
