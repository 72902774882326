<ng-container *ngFor="let alert of alerts">
	<ng-container *ngIf="showAlert(alert.key)">

		<ng-container
			*ngIf="alert.component; else textAlert"
			[ansynEntryComponent]="{ component: alert.component, mapId: mapId}">
		</ng-container>

		<ng-template #textAlert>
			<div
				[attr.tooltip-value]="alert.text | translate"
				tooltip-class="bottom left">
				<span class="indicator" [style.background-color]="alert.background"></span>
				<span class="text">{{ alert.text | translate}}</span>
			</div>
		</ng-template>

	</ng-container>
</ng-container>
