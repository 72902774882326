<div class="cases-table" *ngIf="ids$ | async as ids; else noCases">


	<div class="cases-table-head">
		<div class="cases-table-head-row">
			<div class="cases-table-head-row-data">
				<div class="cases-table-head-col">
					{{'CaseName' | translate}}
				</div>
				<div class="cases-table-head-col">
					{{'Auto Save' | translate}}
				</div>
				<div class="cases-table-head-col">
					{{'Owner' | translate}}
				</div>
				<div class="cases-table-head-col">
					{{'Last Modified' | translate}}
				</div>
			</div>

		</div>
	</div>

	<div class="cases-table-body scrolled"
		 [ngClass]="'rtl' === ('direction' | translate) ? 'rtl' : ''"
		 (ansynInfiniteScroll)="loadCases()" *ngIf="entities$ | async as entities"
         #tbodyElement>

		<div class="cases-table-body-row"
			 *ngFor="let id of ids"
			 (click)="selectCase(id)"
			 [class.active]="(modalCaseId$ | async) === id"
			 [class.selected]="selectedCaseId === id"
			 (mouseenter)="onMouseEnterCaseRow(caseMenu, caseRow, tbodyElement)"
			 (mouseleave)="onMouseLeaveCaseRow(caseRow)"
			 [@leaveAnim]
             #caseRow>

			<hr>

			<div class="cases-table-body-row-data">
				<hr>

				<div class="cases-table-case-name-col">
					{{entities[id].name}}
				</div>

				<ansyn-cases-auto-save class="cases-table-auto-save-col"
									   *ngIf="selectedCaseId === id; else notSelected"
									   [caseId]="id">
				</ansyn-cases-auto-save>

				<ng-template #notSelected>
					<div class="cases-table-case-name-col">
						{{ entities[id].autoSave ? ('AUTOSAVEON' | translate) : ('AUTOSAVEOFF' | translate)}}
					</div>
				</ng-template>

				<div class="cases-table-body-col">
					{{entities[id].owner}}
				</div>

				<div class="cases-table-body-col">
					{{entities[id].lastModified | ansynDate}}
				</div>

				<!--menu-->
				<div class="case-menu-container">
					<div class="case-menu" (click)="caseMenuClick($event, caseRow)" #caseMenu>
						<button (click)="removeCase(id)"
								[attr.tooltip-value]="'Remove' | translate"
								tooltip-class="bottom">
							<i class="icon-delete"></i>
						</button>
						<button (click)="shareCase(id)"
								[attr.tooltip-value]="'Share' | translate"
								tooltip-class="bottom">
							<i class="icon-share"></i>
						</button>
						<button (click)="editCase(id)"
								[attr.tooltip-value]="'Edit' | translate"
								tooltip-class="bottom">
							<i class="icon-edit"></i>
						</button>
					</div>
				</div>

			</div>
		</div>

	</div>
</div>


<ng-template #noCases>

	<p class="no-cases">
		{{'No cases' | translate}}
	</p>

</ng-template>
