<div class="edit-layer" [ngClass]="'rtl' === ('direction' | translate) ? 'rtl' : ''">
	<form #f="ngForm" class="edit-layer-form"
		  (ngSubmit)="layer ? editLayer(layerName.value) : addLayer(layerName.value)">
		<ansyn-input
            #layerName
			[ngModel]="layer?.name"
			select
			name="name"
			[label]="'Name' | translate">
		</ansyn-input>

		<div class="bottom-btns">
			<button type="button" (click)="closeModal()">{{'Cancel' | translate}}</button>
			<button [disabled]="!layerName.value?.trim()">{{'Ok' | translate}}</button>
		</div>
	</form>
</div>
