export const editModeSvg = "m21.7519,11.8105l-2.66813,-3.27l-8.22673,10.0825l2.66813,3.27l8.22673,-10.0825zm1.24513,-1.5805c0.57809,-0.872 0.53363,-2.2345 -0.13341,-3.052c-0.7115,-0.8175 -1.77875,-0.872 -2.53472,-0.1635l2.66813,3.2155zm-10.80593,13.1345l-2.53472,-3.161l-0.31128,3.488l2.846,-0.327zm6.71479,-17.7125c1.423,-1.744 3.77985,-1.744 5.20285,0s1.423,4.578 0,6.322l-10.9838,13.4615l-5.78095,0.7085l0.62256,-7.085l10.93933,-13.407z";

export const measureSvg = "M21.888 15.328l1.888-1.888-2.336-2.368c-0.256-0.256-0.256-0.672 0-0.928s0.672-0.256 0.928 0l2.368 2.336 1.888-1.888-4.704-4.704-16.032 16.032 4.704 4.704 1.888-1.888-2.368-2.336c-0.256-0.256-0.256-0.704 0-0.96s0.704-0.256 0.96 0l2.336 2.368 1.888-1.888-1.408-1.408c-0.256-0.256-0.256-0.672 0-0.96 0.256-0.256 0.672-0.256 0.96 0l1.408 1.44 1.888-1.888-2.368-2.368c-0.256-0.256-0.256-0.672 0-0.928 0.256-0.288 0.672-0.288 0.928 0l2.368 2.336 1.888-1.888-1.408-1.408c-0.256-0.256-0.256-0.672 0-0.928s0.672-0.256 0.928 0l1.408 1.408zM21.44 4.48c0.256-0.256 0.672-0.256 0.96 0l5.632 5.632c0.256 0.288 0.256 0.704 0 0.96l-16.96 16.96c-0.256 0.256-0.672 0.256-0.928 0l-5.664-5.664c-0.256-0.256-0.256-0.672 0-0.928l16.96-16.96z";

export const labelSvg = "M16.608 6.912l7.68 0.16c0.384 0.032 0.672 0.32 0.672 0.672l0.16 7.68c0 0.192-0.064 0.384-0.192 0.512l-9.44 9.408c-0.256 0.256-0.672 0.256-0.928 0l-7.872-7.84c-0.256-0.256-0.256-0.704 0-0.96l9.44-9.408c0.128-0.128 0.288-0.224 0.48-0.224zM16.864 8.256l-8.768 8.768 6.912 6.912 8.768-8.768-0.128-6.752-6.784-0.16zM17.696 11.52c0.768-0.768 2.048-0.768 2.816 0 0.8 0.768 0.8 2.048 0 2.816-0.768 0.8-2.048 0.8-2.816 0-0.8-0.768-0.8-2.048 0-2.816zM19.584 12.48c-0.256-0.288-0.704-0.288-0.96 0-0.256 0.256-0.256 0.672 0 0.928s0.704 0.256 0.96 0c0.256-0.256 0.256-0.672 0-0.928z";

export const editWeightSvg = "M5.344 21.632v-1.312h21.312v1.312h-21.312zM5.344 24.608v-0.64h21.312v0.64h-21.312zM5.344 18.304v-1.984h21.312v1.984h-21.312zM5.344 13.984v-2.656h21.312v2.656h-21.312zM5.344 9.344v-4h21.312v4h-21.312z";

export const editColorSvg = "M24.352 5.344c-0.64 0-1.216 0.224-1.632 0.704l-9.696 10.048c0 0 0 0 0 0s0 0 0 0l-2.144 2.208c-1.792 0.096-4.832 1.248-5.248 5.216-0.064 0.16-0.352 0.896-0.992 0.992-0.448 0.096-0.64 0.448-0.64 0.736 0.032 0.896 1.792 1.248 2.528 1.344 0.256 0.032 0.64 0.064 1.088 0.064 1.92 0 5.184-0.672 6.496-4.928l11.904-12.352c0.416-0.448 0.672-1.056 0.64-1.696 0-0.64-0.224-1.216-0.672-1.664s-1.024-0.672-1.632-0.672zM13.504 17.44l1.472 1.536-1.44 1.472-1.472-1.504 1.44-1.504zM9.568 25.088c-1.312 0.416-2.656 0.32-3.52 0.096 0.576-0.576 0.8-1.312 0.8-1.376 0.032-0.032 0.032-0.064 0.032-0.096 0.352-3.552 3.168-4.032 4.032-4.096l1.92 1.92c-0.608 1.824-1.696 3.040-3.264 3.552zM25.12 8.448l-9.248 9.6-1.504-1.504 9.248-9.6c0.384-0.416 1.088-0.416 1.472 0 0.192 0.192 0.32 0.448 0.32 0.736s-0.096 0.576-0.288 0.768z";

export const deleteSvg = "M12 11.296h2.656c0.384 0 0.672-0.288 0.672-0.64v-0.672h1.344v-0.64h-1.344v0.64h1.344v0.672c0 0.352 0.288 0.64 0.672 0.64h-5.344v10.688h8v-10.688h-8zM20.672 9.984v0h1.344c0.864 0 0.864 1.312 0 1.312h-0.672v11.36c0 0.352-0.32 0.64-0.672 0.64h-9.344c-0.352 0-0.672-0.288-0.672-0.64v-11.36h-0.64v-1.312h1.312c-0.352 0-0.672 0.288-0.672 0.672v0.64h-0.64c-0.896 0-0.896-1.312 0-1.312h4v-1.312c0-0.384 0.288-0.672 0.64-0.672h2.688c0.352 0 0.672 0.288 0.672 0.672v1.312h4v1.312h-0.672v-0.64c0-0.384-0.32-0.672-0.672-0.672zM14.016 13.984c0-0.352 0.288-0.64 0.64-0.64 0.384 0 0.672 0.288 0.672 0.64v5.344c0 0.384-0.288 0.672-0.672 0.672-0.352 0-0.64-0.288-0.64-0.672v-5.344zM18.016 19.328c0 0.384-0.32 0.672-0.672 0.672-0.384 0-0.672-0.288-0.672-0.672v-5.344c0-0.352 0.288-0.64 0.672-0.64 0.352 0 0.672 0.288 0.672 0.64v5.344z";

export const areaSvg = [ "M28.339 24.463l-2.087-2.087c-0.352-0.352-0.895-0.352-1.247 0s-0.352 0.894 0 1.247l0.596 0.596h-17.512v-17.263l0.596 0.596c0.163 0.163 0.407 0.271 0.623 0.271s0.461-0.081 0.623-0.271c0.352-0.352 0.352-0.894 0-1.247l-2.087-2.087c-0.325-0.325-0.949-0.325-1.274 0l-2.087 2.087c-0.352 0.352-0.352 0.894 0 1.247s0.895 0.352 1.247 0l0.596-0.596v19.052h19.274l-0.596 0.596c-0.352 0.352-0.352 0.894 0 1.247 0.163 0.163 0.407 0.271 0.623 0.271s0.461-0.081 0.623-0.271l2.087-2.087c0.163-0.163 0.271-0.434 0.271-0.65 0-0.244-0.081-0.488-0.271-0.65z",
	"M11.846 21.61h11.845c0.488 0 0.895-0.407 0.895-0.895v-11.82c0-0.488-0.407-0.895-0.895-0.895h-11.845c-0.488 0-0.895 0.407-0.895 0.895v11.848c0 0.461 0.407 0.868 0.895 0.868zM12.74 9.762h10.056v10.058h-10.056v-10.058z"
];

// TODO - get icon from @Talschneidy
export const metadataSvg = "M384 736v192q0 40 -28 68t-68 28h-192q-40 0 -68 -28t-28 -68v-192q0 -40 28 -68t68 -28h192q40 0 68 28t28 68zM896 736v192q0 40 -28 68t-68 28h-192q-40 0 -68 -28t-28 -68v-192q0 -40 28 -68t68 -28h192q40 0 68 28t28 68zM1408 736v192q0 40 -28 68t-68 28h-192q-40 0 -68 -28t-28 -68v-192q0 -40 28 -68t68 -28h192q40 0 68 28t28 68z"
