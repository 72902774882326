<div class="presets">
	<div *ngFor="let preset of presets" [ngClass]="'rtl' === ('direction' | translate) ? 'rtl' : ''">
		<button (click)="setPreset(preset)">
			{{presetValue(preset)}} {{presetTitle(preset) | translate}}
		</button>
	</div>
	<button
		type="submit"
		(click)="toggleTimePicker()">
		{{'Custom' | translate}}
	</button>

</div>
