<div class="edit-case" [ngClass]="'rtl' === ('direction' | translate) ? 'rtl' : ''">
	<button (click)="close()" class="close" type="button">
		<span>×</span>
	</button>

	<div class="delete-icon">
		<i class="icon-case-delete"></i>
	</div>

	<form class="edit-case-form" (submit)="onSubmitRemove()">

		<div class="text">
			<i class="icon-delete"></i>&nbsp;{{'Delete' | translate}}&nbsp;"<span class="name">{{activeCase?.name}}</span>"?
		</div>

		<div class="bottom-btns">
			<button type="button" (click)="close()">{{'Cancel' | translate}}</button>
			<button type="submit">{{'OK' | translate}}</button>
		</div>
	</form>

</div>
