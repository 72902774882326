<div class="filter" *ngFor="let filter of filters | slice:0:config.maximumOpen">
	<button ansynComboBoxTrigger
			[disabled]="onlyFavorite"
			[attr.tooltip-value]="getFilterTooltip(filter.displayName) | translate"
			tooltip-class="bottom"
			[class.active]="expand[filter.modelName]"
			[isActive]="expand[filter.modelName]"
			[class.filtered]="isFilter(filter.modelName)"
			[withArrow]="false"
			(click)="expandFilter(filter.modelName)">
		<span class="title">{{filter.displayName | translate}}</span>
		<span class="filter-counter"
			  [class.hide]="filtersMap[filter.modelName]?.title?.length === 0">{{filtersMap[filter.modelName]?.title}}</span>
	</button>

	<div class="filter-container scrolled"> 
		<ansyn-filter-container
			*ngIf="expand[filter.modelName]"
			[filter]="filter">
		</ansyn-filter-container>
	</div>
</div>

<div class="filter more-or-favorites" *ngIf="statusBarConfig?.filterPanelConfig?.active">
	<button ansynComboBoxTrigger *ngIf="filters.length <= config.maximumOpen; else moreFiltersBtn"
			class="favorites"
			[withArrow]="false"
			[disabled]="disableOnlyFavoritesButton && !onlyFavorite"
			[attr.tooltip-value]="'Displays favorites overlays only' | translate"
			tooltip-class="bottom"
			(click)="showOnlyFavorites()">
		<i class="icon"
		   [ngClass]="onlyFavorite ? 'icon-favorite-full'  : 'icon-favorite-empty'"></i>
		<span class="title">{{'Favorites' | translate}}</span>
	</button>
	<ng-template #moreFiltersBtn>
		<button ansynComboBoxTrigger
				class="more-filters"
				[withArrow]="false"
				[attr.tooltip-value]="'More Filters' | translate"
				tooltip-class="bottom"
				(click)="expandFilter(MORE_FILTERS)">
			<i class="icon icon-more"></i>
		</button>
		<div *ngIf="expand[MORE_FILTERS]" class="more-filters-container scrolled">
			<button ansynComboBoxTrigger
					class="favorites"
					[withArrow]="false"
					[disabled]="disableOnlyFavoritesButton && !onlyFavorite"
					[attr.tooltip-value]="'Displays favorites overlays only' | translate"
					tooltip-class="left"
					(click)="showOnlyFavorites(false)">
				<i class="icon"
				   [ngClass]="onlyFavorite ? 'icon-favorite-full'  : 'icon-favorite-empty'"></i>
				<span class="title">{{'Favorites' | translate}}</span>
			</button>
			<div>
				<ansyn-filter-container *ngFor="let filter of filters | slice:config.maximumOpen"
										[filter]="filter">
				</ansyn-filter-container>
			</div>
		</div>
	</ng-template>
</div>
